









































































































































import Vue from "vue";
import { required, email } from "vuelidate/lib/validators";
import { VueTelInput } from "vue-tel-input";
import LayoutWithPageHeader from "@/components/layout/LayoutWithPageHeader.vue";
import CountrySelect from "@/components/form/CountrySelect.vue";
import FormField from "@/components/form/FormField.vue";
import Form from "@/components/form/Form.vue";
import FieldGroup from "@/components/form/FieldGroup.vue";
import FileUpload from "@/components/form/FileUpload.vue";

import { ActionTypes } from "@/store/action-types";
import { OrganizationModel } from "@/models/organization";
import { VueTelInputEvent } from "@/types/types";
import { mapState } from "vuex";
import { OrganizationInput } from "@/generated/graphql";
import cloneDeep from "lodash.clonedeep";

export default Vue.extend({
  components: {
    LayoutWithPageHeader,
    VueTelInput,
    FieldGroup,
    FileUpload,
    CountrySelect,
    FormField,
    Form
  },
  created() {
    this.$storeTyped.dispatch(ActionTypes.FETCH_ORGANIZATION);
  },
  mounted() {
    // Avoid submission of the form when pressing enter key on country selection
    document.querySelector(".vti__dropdown")?.addEventListener("keydown", e => {
      e.preventDefault();
    });
  },
  computed: {
    ...mapState({
      current: state => state.organization.organization
    }),
    phoneClass(): string {
      return this.$v.form.contact &&
        this.$v.form.contact.$dirty &&
        this.$v.form.contact.$error
        ? "form-control is-invalid"
        : "form-control";
    }
  },
  data() {
    return {
      form: null as OrganizationInput | null,
      phoneError: false
    };
  },
  validations: {
    form: {
      name: {
        required
      },
      contact: {
        firstName: {
          required
        },
        lastName: {
          required
        },
        email: {
          required,
          email
        },
        phone: {
          vueTelValid: function(): boolean {
            return !this.phoneError;
          }
        }
      },
      addressLine1: {
        required
      },
      addressLine2: {},
      state: {},
      zip: {
        required
      },
      city: {
        required
      },
      country: {
        required
      },
      logoUrl: {}
    }
  },

  watch: {
    current: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      handler: function({ id, ...val }: OrganizationModel) {
        this.form = cloneDeep(val);
      },
      immediate: true
    }
  },

  methods: {
    setPhone(
      val: string,
      { number, isValid, country, regionCode }: VueTelInputEvent
    ) {
      const hasNumberChanged = number.input !== `+${country.dialCode}`;
      if (this.$v.form.contact) {
        this.$v.form.contact.phone.$reset();
      }
      if (this.form) {
        this.form.contact.phone = hasNumberChanged
          ? {
              ...this.form.contact.phone,
              number: number.input,
              localNumber: number.national,
              internationalNumber: number.international,
              countryCode: regionCode
            }
          : null;
        // Display an error if there is an invalid phone, not if it is empty
        this.phoneError = hasNumberChanged && !isValid;
      }
    },
    async onSubmit() {
      const formData = JSON.parse(JSON.stringify(this.form));
      delete formData.status;
      await this.$storeTyped.dispatch(
        ActionTypes.UPDATE_ORGANIZATION,
        formData
      );
    }
  }
});
